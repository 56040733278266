.tableGeneric {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  margin-top: 8px;
  width: 100%;
  overflow: hidden;
}

.tableGeneric_block {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  margin-top: 8px;
  width: 100%;
  overflow: hidden;
}

.tableGenericwithness {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  margin-top: 8px;
  width: 100%;
  overflow: hidden;
}


.scrollbar {
  justify-content: center;
}

.tableGeneric td,
.tableGeneric th {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tableGeneric_block td,
.tableGeneric_block th {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
}

.heading {
  font-size: 25px;
  font-weight: bold;
  text-align: center;
}

.tableGenericwithness td,
.tableGenericwithness th {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tableGeneric tr:nth-child(even) {
  background-color: #f2f2f2;
}

.tableGeneric tr:hover {
  background-color: #ddd;
}

.tableGeneric_block tr:nth-child(even) {
  background-color: #f2f2f2;
}

.tableGeneric_block tr:hover {
  background-color: #ddd;
}

.tableGeneric th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: center;
  background-color: #1e88e5;
  color: white;
}

/* #04AA6D */

.tableGeneric_block th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: center;
  background-color: #1e88e5;
  color: white;
}

.tableGenericwithness tr:nth-child(even) {
  background-color: #f2f2f2;
}

.tableGenericwithness tr:hover {
  background-color: #ddd;
}

.tableGenericwithness th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: center;
  background-color: #1e88e5;
  color: white;
}

@media (max-width: 400px) {
  .tableGeneric {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    margin-top: 8px;
    margin-left: 17px;
    width: 84%;
    overflow: hidden;
  }

  .tableGeneric_block {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    margin-top: 8px;
    margin-left: 0px;
    width: 84%;
    overflow: hidden;
  }

  .heading {
    font-size: 22px;
    font-weight: bold;
    text-align: center;
  }

  .scrolltobe {
    display: flex;
    width: 100%;
    overflow-x: scroll;
  }

  .head {
    float: left;
    margin-left: 60px;
  }

  .statdiv {
    margin-left: 20px;
  }

}